import {
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
} from '@angular/forms';

export class ValidacaoFormulario {
    static pegarMensagemErro(
        fieldName: string,
        validatorName: string,
        validatorValue: ValidationErrors
    ): string {
        const config: Map<string, string> = new Map();

        config.set('required', `${fieldName} é um campo requerido.`);
        config.set(
            'minlength',
            `${fieldName} precisa ter no mínimo ${validatorValue.requiredLength} caracteres.`
        );
        config.set(
            'maxlength',
            `${fieldName} precisa ter no máximo ${validatorValue.requiredLength} caracteres.`
        );
        config.set('cepInvalido', 'CEP inválido.');
        config.set('cnpjInvalido', 'CNPJ inválido.');
        config.set('cpfInvalido', 'CPF inválido.');
        config.set('emailInvalido', 'Email já cadastrado!');
        config.set('percentualSebrae', '% participação Sebrae inválido');
        config.set('equalsTo', 'Campos não são iguais');
        config.set('pattern', 'Campo inválido');

        return config.get(validatorName) as string;
    }

    // tslint:disable-next-line: typedef
    static percentualValidator(control: FormControl) {
        if (isNaN(control.value)) {
            return null;
        }
        const campo = String(control.value);
        if (campo === '') {
            return null;
        }
        if (
            campo.indexOf(',') !== -1 &&
            campo.indexOf(',') + 1 === String(campo).length
        ) {
            return null;
        }
        if (
            campo.match(/^(\d+(\.|\,))?\d+$/) &&
            Number(campo.replace(',', '.')) >= 0 &&
            Number(campo.replace(',', '.')) <= 100
        ) {
            return null;
        } else {
            return { percentualSebrae: true };
        }
    }

    // inicio - validações customizadas
    // tslint:disable-next-line: typedef
    static requiredMinCheckbox(min = 1) {
        const validator = (formArray: FormArray) => {
            const totalChecked = formArray.controls
                .map((v) => v.value)
                .reduce(
                    (total, current) => (current ? total + current : total),
                    0
                );
            return totalChecked >= min ? null : { required: true };
        };
        return validator;
    }

    // tslint:disable-next-line: typedef
    static cnpjValidator(control: FormControl) {
        let cnpj = control.value;
        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj === '') {
            return null;
        }
        if (cnpj.length !== 14) {
            return { cnpjInvalido: true };
        }
        if (
            cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999'
        ) {
            return { cnpjInvalido: true };
        }
        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        const digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        // tslint:disable-next-line: triple-equals
        if (resultado != digitos.charAt(0)) {
            return { cnpjInvalido: true };
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        // tslint:disable-next-line: triple-equals
        if (resultado != digitos.charAt(1)) {
            return { cnpjInvalido: true };
        }
        return null;
    }

    // tslint:disable-next-line: typedef
    static cpfValidator(control: FormControl) {
        const val = control.value;
        // tslint:disable-next-line: triple-equals
        if (val.length == 11 || val.length == 14) {
            let cpf = val.trim();

            cpf = cpf.replace(/\./g, '');
            cpf = cpf.replace('-', '');
            cpf = cpf.split('');

            let v1 = 0;
            let v2 = 0;
            let aux = false;

            for (let i = 1; cpf.length > i; i++) {
                // tslint:disable-next-line: triple-equals
                if (cpf[i - 1] != cpf[i]) {
                    aux = true;
                }
            }

            // tslint:disable-next-line: triple-equals
            if (aux == false) {
                return { cpfInvalido: true };
            }

            for (let i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
                v1 += cpf[i] * p;
            }

            v1 = (v1 * 10) % 11;

            // tslint:disable-next-line: triple-equals
            if (v1 == 10) {
                v1 = 0;
            }

            // tslint:disable-next-line: triple-equals
            if (v1 != cpf[9]) {
                return { cpfInvalido: true };
            }

            for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
                v2 += cpf[i] * p;
            }

            v2 = (v2 * 10) % 11;

            // tslint:disable-next-line: triple-equals
            if (v2 == 10) {
                v2 = 0;
            }

            // tslint:disable-next-line: triple-equals
            if (v2 != cpf[10]) {
                return { cpfInvalido: true };
            } else {
                return null;
            }
        } else if (val === '') {
            return null;
        } else {
            return { cpfInvalido: true };
        }
    }
    // tslint:disable-next-line: typedef
    static cepValidator(control: FormControl) {
        const cep = control.value;
        if (cep && cep !== '') {
            const validacep = /^[0-9]{8}$/;
            return validacep.test(cep) ? null : { cepInvalido: true };
        }
        return null;
    }

    // tslint:disable-next-line: typedef
    static equalsTo(otherField: string) {
        const validator = (formControl: FormControl) => {
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (
                !formControl.root ||
                !(formControl.root as FormGroup).controls
            ) {
                return null;
            }

            const field = (formControl.root as FormGroup).get(otherField);

            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }

            if (field.value !== formControl.value) {
                return { equalsTo: otherField };
            }

            return null;
        };
        return validator;
    }
    // fim - validações customizadas
}
