import { Convenio } from './../../data/models/projetos/convenio';
import { Component, Input } from '@angular/core';
import { Projeto } from '@data/models/projetos/projeto';

@Component({
    selector: 'sbcnv-barra-convenio',
    templateUrl: './barra-convenio.component.html',
    styleUrls: ['./barra-convenio.component.scss'],
})
export class BarraConvenioComponent {
    @Input() public convenio!: Convenio;
    @Input() public projeto!: Projeto;
    constructor() { }
}
